import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';
import {BUTTON} from '~/queries/sanity/elements/button';
import {VIDEO} from '~/queries/sanity/elements/video';

export const SECTION_BANNER = groq`
  _type == 'section.banner' => {
    _key,
    preTitle,
    title,
    display,
    variant,
    description,
    image{
      ${IMAGE}
    },
    video {
      ${VIDEO}
    },
    button {
      ${BUTTON}
    }
  }
`;
