import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';

export const CAR_CARD = groq`
  _id,
  'slug': slug.current,
  title,
  featuredImage {
    ${IMAGE}
  },
  logo {
    ${IMAGE}
  },
  website,
  models[]
`;
