import groq from 'groq';
import {CAR_CARD} from '~/queries/sanity/objects/car-card';

export const SECTION_CARBRANDS = groq`
  _type == 'section.carBrands' => {
    _key,
    title,
    selected[]->{
      ${CAR_CARD}
    }
  }
`;
