import groq from 'groq';
import {COLOR} from '../elements/color';

export const MODULE_CALLOUT = groq`
	text,
  subtitle,
  theme-> {
    background {
      ${COLOR}
    },
    text {
      ${COLOR}
    }
  },
`;
