import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';

export const COLLECTION_CARD = groq`
  _id,
  "handle": store.slug.current,
  "gid": store.gid,
  "slug": "/collections/" + store.slug.current,
  "title": store.title,
  "image": store.imageUrl,
  media {
    featuredImage {
      ${IMAGE}
    },
    secondaryImage {
      ${IMAGE}
    }
  },
`;
