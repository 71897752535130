import groq from 'groq';
import {VIDEO} from '~/queries/sanity/elements/video';
export const SECTION_ORDER_INTRO = groq`
  _type == 'section.orderIntro' => {
    _key,
    title,
    body,
    video {
      ${VIDEO}
    }
  }
`;
