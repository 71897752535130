import groq from 'groq';
import {IMAGE} from '../elements/image';

export const SECTION_GALLERY = groq`
  _type == 'section.gallery' => {
    _key,
    title,
    images[] {
      ${IMAGE}
    }

  }
`;
