import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';
export const SECTION_ORDER_FEATURES = groq`
  _type == 'section.orderFeatures' => {
    _key,
    features[] {
      _key,
      title,
      body,
      image {
        ${IMAGE}
      },
    },
  }
`;
