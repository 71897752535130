import groq from 'groq';
import {PORTABLE_TEXT} from '../portable-text/portable-text';

export const SECTION_PORTABLE_TEXT_BLOCK = groq`
  _type == 'section.portableTextBlock' => {
    _key,
    body[] {
      ${PORTABLE_TEXT}
    },
    "date": coalesce(date, ^._updatedAt)
  }
`;
