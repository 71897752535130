import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';

export const JOURNAL_CARD = groq`
  _id,
  'slug': slug.current,
  title,
  category->{
    title,
    slug,
  },
  featuredImage {
    ${IMAGE}
  },
`;
