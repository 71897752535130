import groq from 'groq';
import {LINK_EXTERNAL} from '../elements/linkExternal';
import {LINK_INTERNAL} from '../elements/linkInternal';
import {PRODUCT_WITH_VARIANT} from '../objects/product-with-variant';

// import {PRODUCT_WITH_VARIANT} from '../productWithVariant';

export const MARK_DEFS = groq`
	...,
	_type == 'LinkExternal' => {
	  ${LINK_EXTERNAL}
	},
	_type == 'LinkInternal' => {
		${LINK_INTERNAL}
	},
	_type == 'LinkProduct' => {
		linkAction,
		productWithVariant {
			...${PRODUCT_WITH_VARIANT}
		},
		linkAction != 'link' => {
			quantity
		}
	},

`;
