import groq from 'groq';
import {PRODUCT_CARD} from '~/queries/sanity/objects/product-card';

export const SECTION_PRODUCTS = groq`
  _type == 'section.products' => {
    _key,
    title,
    description[],
    "products": selected[]->{
      _id
    }
  }
`;
