import groq from 'groq';
import {COLLECTION_CARD} from '~/queries/sanity/objects/collection-card';

export const SECTION_COLLECTIONS = groq`
  _type == 'section.collections' => {
    _key,
    title,
    selected[]->{
      ${COLLECTION_CARD}
    }
  }
`;
