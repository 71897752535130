import groq from 'groq';

export const COLOR = groq`
  hex,
  rgb {
    r,
    g,
    b
  },
`;
