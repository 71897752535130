import groq from 'groq';
import {IMAGE} from '../elements/image';
import {PRODUCT_HOTSPOT} from './product-hotspot';

export const IMAGE_WITH_PRODUCT_HOTSPOTS = groq`
  _key,
  _type,
  image {
    ${IMAGE}
  },
  productHotspots[] {
    _key,
    ${PRODUCT_HOTSPOT},
  },
`;
