import groq from 'groq';
import {BUTTON} from '~/queries/sanity/elements/button';
import {BADGE} from '~/queries/sanity/elements/badge';

export const TITLE_GROUP = groq`
  _type == 'module.titleGroup' => {
    _key,
    labels[] {
      ${BADGE}
    },
    preTitle,
    title,
    description[],
    actions[] {
      ${BUTTON}
    }
  }
`;
