import groq from 'groq';
import {MUX_VIDEO} from './muxVideo';
import {IMAGE} from './image';

export const VIDEO = groq`
  _key,
  _type,
  title,
  caption,
  thumbnail {
    ${IMAGE}
  },
  thumbnailMobile {
    ${IMAGE}
  },
  "asset": video {
    ${MUX_VIDEO}
  }.asset,
  "assetMobile": videoMobile {
    ${MUX_VIDEO}
  }.asset,
  options {
    ...
  }
`;
