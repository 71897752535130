import groq from 'groq';
import {BUTTON} from '~/queries/sanity/elements/button';
import {CAR_CARD} from './car-card';
import {BADGE} from '../elements/badge';

export const CONTENT_GROUP = groq`
  _type == 'module.contentGroup' => {
    _key,
    contentType,
    labels[] {
      ${BADGE}
    },
    preTitle,
    title,
    description[],
    carBrand->{
        ${CAR_CARD}
    },
    actions[] {
      ${BUTTON}
    }
  }
`;
