import groq from 'groq';
import {IMAGE} from '../elements/image';

export const SECTION_PAGE_HEADING = groq`
  _type == 'section.pageHeading' => {
    _key,
    title,
    theme,
  }
`;
