import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';
import {BUTTON} from '~/queries/sanity/elements/button';
import {VIDEO} from '~/queries/sanity/elements/video';

export const SECTION_HERO_IMAGE = groq`
  _type == 'section.heroImage' => {
    _key,
    title,
    pretitle,
    caption,
    display,
    image {
      ${IMAGE}
    },
    video {
      ${VIDEO}
    },
    button {
      ${BUTTON}
    },
    scrollDown,
    variant,
    overlay,
  }
`;
