import groq from 'groq';
import {IMAGE} from '../elements/image';

export const PRODUCT_WITH_VARIANT = groq`
  product->{
    _id,
    "available": !store.isDeleted && store.status == 'active',
    "gid": store.gid,
    "slug": store.slug.current,
    "variantGid": coalesce(^.variant->store.gid, store.variants[0]->store.gid),
    "selectedColor": coalesce(^.variant->store.option2, store.variants[0]->store.option2),
    galleries[] {
      color,
      media[1] {
        _type == 'image' => {
          ${IMAGE}
        },
      }
    }
  }
`;
