import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';
import {BUTTON} from '../elements/button';
import {COLOR} from '../elements/color';
import {VIDEO} from '../elements/video';
import {IMAGE_WITH_PRODUCT_HOTSPOTS} from '../objects/image-with-product-hotspots';

export const SECTION_SNAPSHOT = groq`
  _type == 'section.snapshot' => {
    _key,
    components[] {
      _type == 'banner' => {
        _key,
        _type,
        title,
        button {
          ${BUTTON}
        },
        "media": @.media.modules[0] {
          _type == 'image' => {
            ${IMAGE}
          },
          _type == 'module.video' => {
            ${VIDEO}
          }
        },
        theme-> {
          background {
            ${COLOR}
          },
          text {
            ${COLOR}
          }
        },
      },
      _type == 'imageWithProductHotspots' => {
        ${IMAGE_WITH_PRODUCT_HOTSPOTS}
      },
      _type == 'media' => {
        _key,
        _type,
        "media": @.modules[0] {
          _type == 'image' => {
            ${IMAGE}
          },
          _type == 'module.video' => {
            ${VIDEO}
          }
        },
      },
    }
  }
`;
