import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';
import {BUTTON} from '~/queries/sanity/elements/button';
import {VIDEO} from '~/queries/sanity/elements/video';
import {CAR_CARD} from '../objects/car-card';

export const SECTION_CUSTOM_HERO_IMAGE = groq`
  _type == 'section.customHeroImage' => {
    _key,
    contentType,
    title,
    pretitle,
    caption,
    display,
    image {
      ${IMAGE}
    },
    video {
      ${VIDEO}
    },
    button {
      ${BUTTON}
    },
    scrollDown,
    variant,
    overlay,
    'selected': selected->type[0].selected[]->{
      ${CAR_CARD}
    }
  }
`;
