import groq from 'groq';
import {JOURNAL_CARD} from '~/queries/sanity/objects/journal-card';

export const SECTION_JOURNAL = groq`
  _type == 'section.journal' => {
    _key,
    title,
    selected[]->{
      ${JOURNAL_CARD}
    }
  }
`;
