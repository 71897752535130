import groq from 'groq';
import {IMAGE} from '../elements/image';

export const SECTION_PRODUCT_FEATURES = groq`
  _type == 'section.productFeatures' => {
    _key,
    features[] {
      _key,
      title,
      description,
      image {
        ${IMAGE}
      }
    }
  }
`;
