import groq from 'groq';
import {SECTION_HERO_IMAGE} from './sections/heroImage';
import {SECTION_COLLECTIONS} from './sections/collections';
import {SECTION_CAllOUT} from './sections/callout';
import {SECTION_BANNER} from './sections/banner';
import {SECTION_JOURNAL} from './sections/journal';
import {SECTION_PRODUCTS} from './sections/products';
import {SECTION_MEDIA_TEXT} from './sections/mediaText';
import {SECTION_GALLERY} from './sections/gallery';
import {SECTION_SNAPSHOT} from './sections/snapshot';
import {SECTION_INCENTIVES} from './sections/incentives';
import {SECTION_TEXT_ACCORDIONS} from './sections/textAccordions';
import {SECTION_PRODUCT_FEATURES} from './sections/productFeatures';
import {SECTION_PRODUCT_GROUP} from './sections/productGroup';
import {SECTION_PORTABLE_TEXT_BLOCK} from './sections/portableTextBlock';
import {SECTION_PAGE_HEADING} from './sections/pageHeading';
import {SECTION_ORDER_INTRO} from './sections/orderIntro';
import {SECTION_ORDER_FEATURES} from './sections/orderFeatures';
import {SECTION_CARBRANDS} from './sections/carBrands';
import {SECTION_CUSTOM_HERO_IMAGE} from './sections/customHeroImage';
import {SECTION_CUSTOM_MEDIA_TEXT} from './sections/customMediaText';
const SHAREABLE_SECTIONS = groq`
  _key,
  _type,
  ${SECTION_HERO_IMAGE},
  ${SECTION_COLLECTIONS},
  ${SECTION_CAllOUT},
  ${SECTION_BANNER},
  ${SECTION_JOURNAL},
  ${SECTION_PRODUCTS},
  ${SECTION_MEDIA_TEXT},
  ${SECTION_GALLERY},
  ${SECTION_SNAPSHOT},
  ${SECTION_INCENTIVES},
  ${SECTION_PRODUCT_FEATURES},
  ${SECTION_PRODUCT_GROUP},
  ${SECTION_TEXT_ACCORDIONS},
  ${SECTION_PORTABLE_TEXT_BLOCK},
  ${SECTION_PAGE_HEADING},
  ${SECTION_ORDER_INTRO},
  ${SECTION_ORDER_FEATURES},
  ${SECTION_CARBRANDS},
  ${SECTION_CUSTOM_HERO_IMAGE},
  ${SECTION_CUSTOM_MEDIA_TEXT}
  //avoid trailing comma for last item
`;
export const SECTIONS = groq`
  ${SHAREABLE_SECTIONS},
  _type == 'section.sections' => {
    _key,
    "sections": selected->type[] {
      ${SHAREABLE_SECTIONS}
    }
  }
`;
