import groq from 'groq';
import {IMAGE} from '../elements/image';

export const SECTION_PRODUCT_GROUP = groq`
  _type == 'section.productGroup' => {
    _key,
    title,
    image {
      ${IMAGE}
    },
    products[]->{
      _id
    }

  }
`;
