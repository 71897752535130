import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';
import {TITLE_GROUP} from '~/queries/sanity/objects/title-group';

export const SECTION_MEDIA_TEXT = groq`
  _type == 'section.mediaText' => {
    _key,
    titleGroup {
      ${TITLE_GROUP}
    },
    switchOrder,
    image {
      ${IMAGE}
    },
  }
`;
