import groq from 'groq';

export const SECTION_TEXT_ACCORDIONS = groq`
  _type == 'section.textAccordions' => {
    _key,
    variant,
    intro,
    accordions[] {
      _key,
      title,
      body
    }
  }
`;
