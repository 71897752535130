import groq from 'groq';
import {IMAGE} from '../elements/image';

export const SECTION_INCENTIVES = groq`
  _type == 'section.incentives' => {
    _key,
    items[] {
      _key,
      title,
      icon {
        ${IMAGE}
      },
      text
    }
  }
`;
