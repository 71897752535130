import groq from 'groq';
import {COLOR} from '../elements/color';
import {MODULE_IMAGE} from './image';

export const MODULE_IMAGES = groq`
  width,
  verticalAlign,
  "count": count(modules),
  theme-> {
    background {
      ${COLOR}
    },
    text {
      ${COLOR}
    }
  },
  modules[] {
    _key,
    ${MODULE_IMAGE}
  }
`;
