import groq from 'groq';
import {IMAGE} from '~/queries/sanity/elements/image';
import {CONTENT_GROUP} from '../objects/content-group';
import {VIDEO} from '../elements/video';

export const SECTION_CUSTOM_MEDIA_TEXT = groq`
  _type == 'section.customMediaText' => {
    _key,
    display,
    contentGroup {
      ${CONTENT_GROUP}
    },
    switchOrder,
    image {
      ${IMAGE}
    },
    video {
      ${VIDEO}
    },
  }
`;
